@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }
}

body {
  overflow: hidden;
}

.bn-block-content.ProseMirror-selectednode > *,
.ProseMirror-selectednode > .bn-block-content > * {
  outline: none !important;
}
.block-render .bn-editor.ProseMirror {
  padding: 0;
}
.bn-menu-dropdown {
  min-width: 380px !important;
}

table {
  border-collapse: collapse;
  table-layout: auto;
  border-spacing: 1px;
}

table td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px 16px;
}

.required-label {
  display: inline-flex; /* Ensure that the content remains in-line */
  align-items: center; /* Center vertically if needed */
}

.required-label p {
  display: inline; /* Ensure the <p> is inline */
  margin: 0; /* Remove any default margin */
}

.required-label p::after {
  content: ' *'; /* Add the asterisk */
  /* color: rgb(159 18 57); Set the color of the asterisk */
  color: rgb(185 28 28);
}

.bn-block-content[data-content-type='heading'][data-level='1'] {
  font-family: 'Inter', sans-serif;
  font-size: 32px !important;
  font-weight: 600 !important;
}

.bn-block-content[data-content-type='heading'][data-level='2'] {
  font-family: 'Inter', sans-serif;
  font-size: 26px !important;
  font-weight: 600 !important;
}

.bn-block-content[data-content-type='heading'][data-level='3'] {
  font-family: 'Inter', sans-serif;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.bn-block-content[data-content-type='paragraph'] {
  font-family: 'Inter', sans-serif;
  font-size: 16px !important;
  font-weight: 400 !important;
}
